import Vue from 'vue'
import Antd from 'ant-design-vue';
import { message } from 'ant-design-vue';
import App from './App';
import Cookies from 'js-cookie'
import router from "./router/router.js"
import * as Db from "./utils/db.js"
import * as common from './utils/common.js'
import 'ant-design-vue/dist/antd.min.css';
import './router/router-guards.js';
import store from './store'

window.IMGHOST = 'https://junongzhenpin.oss-cn-fuzhou.aliyuncs.com';
Vue.config.productionTip = false;

Vue.prototype.$db = Db;
Vue.prototype.$isClick = false;
Vue.prototype.$common = common;
Vue.prototype.$Cookies = Cookies;
Vue.prototype.$message = message;
Vue.prototype.$isGetMenus = false;
Vue.prototype.$menu_list = [];

Vue.use(Antd);
Vue.prototype.$_this = new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
}).$mount('#app')
