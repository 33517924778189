<template>
  <div>
    <a-menu
      mode="inline"
      theme="dark"
      :openKeys="openKeys"
      :selectedKeys="[$route.name]"
      :default-selected-keys="defaultSelectedKeys"
      @openChange="onOpenChange"
    >
      <template v-for="(item,index) in reversedMenu_list">
        <a-sub-menu :key="index" v-if="item.children&&item.children.length>0">
          <span slot="title">
            <a-icon :type="item.meta.icon" />
            <span>{{item.meta.title}}</span>
          </span>
          <a-menu-item v-for="(t) in item.children" :key="t.path">
            <router-link :to="t.path">
              <span>{{t.meta.title}}</span>
            </router-link>
          </a-menu-item>
        </a-sub-menu>
        <a-menu-item :key="item.path" v-else>
          <router-link :to="item.path">
            <a-icon :type="item.meta.icon" />
            <span>{{item.meta.title}}</span>
          </router-link>
        </a-menu-item>
      </template>
    </a-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  data() {
    return {
      openKeys: [""],
      defaultSelectedKeys: [this.$route.name],
    }
  },
  computed: {
    ...mapGetters(["getMenu"]),
    reversedMenu_list() {
      let menu_list = this.getMenu
      if (menu_list.length > 0 && menu_list[0].id != 0) {
        menu_list.unshift({
          meta: { title: "首页", icon: "home" },
          id: 0,
          sort: 0,
          hidden: false,
          children: [],
          path: "/index",
        })
      }
      return menu_list
    },
  },
  mounted() {
    this.xhmenu()
  },
  methods: {
    onOpenChange(openKeys) {
      if (openKeys.length !== 0) {
        this.openKeys = [openKeys[1]]
      } else {
        this.openKeys = [""]
      }
    },
    // 遍历菜单
    xhmenu() {
      for (let i = 0; i < this.reversedMenu_list.length; i++) {
        if (this.reversedMenu_list[i].children !== false) {
          for (let y = 0; y < this.reversedMenu_list[i].children.length; y++) {
            if (
              this.reversedMenu_list[i].children[y].path === this.$route.name
            ) {
              this.openKeys = [i]
              break
            }
          }
        }
      }
    },
  },
}
</script>

<style>
</style>