import Vue from "vue";
import VueRouter from "vue-router";

// 引入组件
import login from "@/views/login/login.vue";
import Layout from '@/layout'
// 要告诉 vue 使用 vueRouter
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) {
		return originalPush.call(this, location, onResolve, onReject)
	}
	return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.originalReplace = function push(location, onResolve, onReject) {
	if (onResolve || onReject) {
		return originalReplace.call(this, location, onResolve, onReject)
	}
	return originalReplace.call(this, location).catch(err => err)
}

const routes = [
	{
		path: "/login",
		hidden: true,
		component: () => import('@/views/login/login.vue')
	},
	{
		path: '/redirect',
		component: Layout,
		hidden: true,
		children: [
			{
				path: '/redirect/:path*',
				component: () => import('@/views/redirect/index')
			}
		]
	},
	{
		path: '/',
		component: Layout,
		href: 'pageindex',
		redirect: 'noRedirect',
		children: [
			{
				path: 'index',
				component: () => import('@/views/index/index'),
				name: '/index',
				meta: { title: '首页', icon: 'home' }
			}
		]
	},
	{
	  path: '/orders/detail',
	  component: () => import('@/views/orders/detail'),
	  name: '/orders/index',
	  meta: { title: '订单详情' }
	},

]

var router = new VueRouter({
	routes,
	mode: "history"
})
export default router;