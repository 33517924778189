import axios from 'axios';
import { message, Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getToken, removeToken } from '@/utils/auth';

// 创建一个 Axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['Authorization'] = getToken();
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.code !== 200) {
      showErrorMessage(res.msg || 'Error');
      return Promise.reject(new Error(res.msg || 'Error'));
    } else {
      if (res.code !== 200) {
        showErrorMessage(res.msg || 'Error');
        if (res.code === 401) {
          showLogoutConfirm();
        }
        return Promise.reject(new Error(res.msg || 'Error'));
      } else {
        return res.result;
      }
    }
  },
  error => {
    if (error.response && error.response.data && error.response.data.code === 401) {
      showLogoutConfirm();
    } else {
      showErrorMessage((error.response && error.response.data ? error.response.data.msg : false) || 'Error');
    }
    return Promise.reject(error.response ? error.response.data : error);
  }
);

// 显示错误消息
function showErrorMessage(msg) {
  message.error(msg, 5);
}

// 显示退出确认对话框
function showLogoutConfirm() {
  Modal.confirm({
    title: '温馨提示',
    icon: createVNode(ExclamationCircleOutlined),
    content: createVNode('div', { style: 'color:red;' }, '您已退出，您可以取消停留在此页面，或重新登录'),
    onOk() {
      removeToken();
      location.reload();
    },
    onCancel() {
      removeToken();
      location.reload();
    },
    class: 'test'
  });
}

export default service;
