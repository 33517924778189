<template>
  <div style="width:100%;height: 100%;">
    <a-layout id="components-layout-demo-custom-trigger" style="height:100vh">
      <a-layout-sider v-model="collapsed" :trigger="null" collapsible class="layout-sider">
        <div class="logo" />
        <Menu></Menu>
      </a-layout-sider>
      <a-layout class="adm" :style="{ marginLeft: !collapsed?'200px':'80px' }">
        <a-layout-header
          class="flex justify-between align-center"
          style="background: #fff; padding: 0;transition: width 0.2s;"
          :style="{ position: 'fixed', zIndex: 999 ,width:collapsed?'calc(100% - 80px)':'calc(100% - 200px)'}"
        >
          <div class="flex justify-start align-center">
            <a-icon
              class="trigger"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="collapsedClick"
            />
            <breadcrumb></breadcrumb>
          </div>

          <a-dropdown class="mr20 ml10">
            <div class="flex justify-start align-center">
              <img style="width: 30px;height: 30px;border-radius: 5px;" src="../assets/avatar.gif" />
              <a class="ant-dropdown-link ml10" @click="e => e.preventDefault()">
                {{userInfo.username}}
                <a-icon type="down" />
              </a>
            </div>
            <a-menu slot="overlay">
              <a-menu-item @click="showDialog">修改信息</a-menu-item>
              <a-menu-item @click="dbDelCache">清除缓存</a-menu-item>
              <a-menu-divider />
              <a-menu-item @click="loginOut">退出</a-menu-item>
            </a-menu>
          </a-dropdown>
        </a-layout-header>
        <a-layout-content class="layout-content">
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
    <a-modal :maskClosable="false" v-model="dialogVisible" title="修改个人信息">
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-form-item label="手机号">
          <a-input
            v-decorator="[
	  				  'mobile',
	  				  { rules: [
	  					  { required: true , message: '请输入手机号'}
	  					] 
	  				  },
	  				]"
            placeholder="请输入手机号"
          />
        </a-form-item>
        <a-form-item label="原密码">
          <a-input
            v-decorator="[
					  'oldPwd',
					  { rules: [
						  { required: true , min: 6, max: 18, message: '请输入原密码'}
						] 
					  },
					]"
            placeholder="请输入原密码"
          />
        </a-form-item>
        <a-form-item label="新密码">
          <a-input
            v-decorator="[
	  				  'newPwd',
	  				  { rules: [
	  					  { required: true , min: 6, max: 18, message: '密码长度必须为6-18个字符之间'}
	  					] 
	  				  },
	  				]"
            placeholder="请输入密码(6-18位)"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <a-button key="back" @click="dialogVisible=false">取消</a-button>
        <a-button key="submit" type="primary" :loading="submitLoading" @click="userSubmit">提交</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { region, logistics, editInfo } from "@/api/common"
import Breadcrumb from "@/components/Layout/Breadcrumb.vue"
import Menu from "@/components/Layout/Menu.vue"
import { logout } from "@/api/common"
import { removeToken, set, get, del, remove } from "@/utils/auth"

export default {
  components: { Breadcrumb, Menu },
  data() {
    return {
      collapsed: false,
      userInfo: {},
      dialogVisible: false,
      form: this.$form.createForm(this, {
        mobile: "",
        oldPwd: "",
        newPwd: "",
      }),
      submitLoading: false,
    }
  },

  mounted() {
    this.userInfo = get("userInfo")

    //判断是否需要重新获取（10天获取一次）
    let newtime = new Date().getTime()
    let logisticsdata = get("logisticsList")
    if (logisticsdata) {
      if (newtime - logisticsdata.time > 24 * 60 * 60 * 1000) {
        //一天获取一次
        this.get_logistics()
      }
    } else {
      this.get_logistics()
    }
    this.get_common_region()
  },
  methods: {
    async dbDelCache() {
      del("regionList")
      del("logisticsList")
      this.$message.success("操作成功")
      window.location.reload()
    },
    // 获取省市区地址
    async get_common_region() {
      let _this = this
      function common_region(params) {
        region().then((res) => {
          set("regionList", { data: res, time: new Date().getTime() })
        })
      }
      // 判断是否需要重新获取（10天获取一次）
      let newtime = new Date().getTime()
      let regiondata = get("regionList")
      if (regiondata) {
        if (newtime - regiondata.time > 10 * 24 * 60 * 60 * 1000) {
          common_region()
        }
      } else {
        common_region()
      }
    },
    // 获取快递/物流公司
    async get_logistics() {
      logistics().then((res) => {
        set("logisticsList", { data: res, time: new Date().getTime() })
      })
    },
    /*
	  		修改个人信息、修改成功后重新登录
	  */
    async userSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let model = Object.assign({}, this.form.getFieldsValue())
          editInfo(model).then((res) => {
            this.$message.success("操作成功")
            this.loginOut()
          })
        }
      })
    },
    showDialog() {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.form.setFieldsValue({
          mobile: this.userInfo.mobile,
          oldPwd: "",
          newPwd: "",
        })
      })
    },

    /*
		退出登录，清理缓存、个人信息
	  */
    async loginOut() {
      logout().then(() => {
        removeToken()
        del("userInfo")
        location.reload()
      })
    },
    collapsedClick() {
      this.collapsed = !this.collapsed
    },
  },
}
</script>
<style>
#components-layout-demo-custom-trigger .layout-sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
}
.layout-content {
  margin: 80px 16px 16px;
  min-height: 280px;
}
#components-layout-demo-custom-trigger .trigger {
  display: inline-block;
  font-size: 16px;
  /* line-height: 64px; */
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.adm {
  transition: margin-left 0.2s;
}
</style>