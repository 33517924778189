import Cookies from 'js-cookie'

const TokenKey = 'Admin-xiaofu-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export const get = (key) => {
  const value = localStorage.getItem(key);
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
}

export const set = (key, val) => {
  const value = typeof val === 'string' ? val : JSON.stringify(val);
  localStorage.setItem(key, value);
}

export function del(key) {
  localStorage.removeItem(key);
}
export function remove() {
  localStorage.clear();
}