import router from './router.js'
import store from '../store'
import Layout from '@/layout'
import NProgress from 'nprogress'
import { getToken, removeToken } from '@/utils/auth'
import { getMenus } from '@/api/common'
import 'nprogress/nprogress.css'
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
    NProgress.start(to.path)
    if (to.path === '/noRedirect') {
        removeToken()
    }
    // 设置网站名称
    document.title = getPageTitle(to.meta.title)
    const hasToken = getToken()
    if (hasToken) {
        if (to.path === '/login') {
            next({ path: '/' })
            NProgress.done()
        } else {
            const menu = store.getters.getMenu
            if (menu.length > 0) {
                next()
                NProgress.done()
            } else {
                try {
                    const data = await getMenus()
                    if (data.length == 0) {
                        this.$message.warning("请配置账号权限")
                        next(`/login?redirect=${to.path}`)
                    }
                    const routerData = data.map(menu => {

                        const route = {
                            component: Layout,
                            path: menu.href,
                            children: [],
                            meta: {
                                title: menu.name,
                                icon: menu.icon || 'ant-cloud',
                                noCache: true
                            },
                            name: menu.href
                        }
                        if (menu.children.length > 0) {
                            route.redirect = 'noRedirect'
                            menu.children.forEach(child => {
                                const childRoute = {
                                    path: child.href,
                                    component: resolve => require([`@/views${child.href}`], resolve),
                                    meta: {
                                        title: child.name,
                                        icon: child.icon,
                                        noCache: true
                                    },
                                    name: child.href
                                }
                                route.children.push(childRoute)
                            })
                        } else {
                            route.redirect = menu.href
                        }
                        return route
                    })
                    router.addRoutes(routerData)
                    store.commit('setMenu', routerData)
                    next({ ...to, replace: true })
                    NProgress.done()
                } catch (error) {
                    next(`/login?redirect=${to.path}`)
                    NProgress.done()
                }
            }
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            const accessRoutes = []
            router.addRoutes(accessRoutes)
            next()
        } else {
            next(`/login?redirect=${to.path}`)
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    NProgress.done()
})
