import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        menu: []
    },
    getters: {
        getMenu(state) {
            return state.menu
        }
    },
    mutations: {
        setMenu(state, list) {
            state.menu = list
        }
    },
});
