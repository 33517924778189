import request from '@/utils/request'

// 登录
export function login(data) {
  return request({
    url: '/merchant/common/login',
    method: 'post',
    data: data
  })
}
// 退出登录
export function logout(data) {
  return request({
    url: '/merchant/common/logout',
    method: 'post',
    data: data
  })
}

//获取权限菜单
export function getMenus(data) {
  return request({
    url: '/merchant/common/menu',
    method: 'get',
    params: data
  })
}
//图片上传
export function uploader(data) {
  return request({
    url: '/merchant/common/uploader',
    method: 'get',
    params: data
  })
}

//获取物流/快递公司
export function logistics(data) {
  return request({
    url: '/merchant/common/logistics',
    method: 'get',
    params: data
  })
}
//获取所有地区
export function region(data) {
  return request({
    url: '/merchant/common/region',
    method: 'get',
    params: data
  })
}
//更新个人信息
export function editInfo(data) {
  return request({
    url: '/merchant/common/editInfo',
    method: 'post',
    data: data
  })
}

